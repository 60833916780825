













import { computed, defineComponent } from "@vue/composition-api";
import APP_CONFIG from "../modules/config";
import useWatchPropsValue from "./useWatchPropsValue";

export default defineComponent({
  name: "MonthSelect",
  props: { value: { type: String } },
  setup(props, { emit }) {
    const { valueRef } = useWatchPropsValue(props);
    const computedValue = computed({
      get() {
        // nilai bulan januari adalah 0, jadi tidak bisa menggunakan
        // pengecekan boolean valueRef.value, karena januari akan dianggap
        // false
        return valueRef.value !== "" ? valueRef.value : null;
      },
      set(value: string) {
        valueRef.value = value;
        let realValue: string | null = null;
        if (![null, undefined, ""].includes(value)) {
          realValue = value.toString();
        }
        emit("input", realValue);
      },
    });

    return {
      // Data
      fieldTitle: "Bulan",
      months: APP_CONFIG.months,

      // Computed
      computedValue,
    };
  },
});
